import { createRoot } from 'react-dom/client'

// import '@unimpaired/react/style.css' // This allows our style library to work
import './index.css' // This allows tailwind to work
import { App } from './App'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)
